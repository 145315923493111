<template>
	<div>
		<v-form
			class="lg:tw-pt-10"
			ref="form"
			lazy-validation
			@submit.prevent="validateBranch"
		>
			<h3
				class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			>
				{{ $t('branch.branchDetaiks') }}
			</h3>
			<v-row>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3 tw-mb-4">
							<v-icon color="#D5D5D5"
								>mdi-office-building-outline</v-icon
							>
							<span class="tw-font-medium tw-text-black">
								{{ $t('branch.branchName') }}
							</span>
						</div>
						<v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:rules="[rules.required($t('branch.branchName'))]"
							v-model="branch.name"
							:placeholder="$t('branch.branchNamePlaceholder')"
							hide-details="auto"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:error-messages="error.name ? error.name[0] : []"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5"
								>mdi-office-building-outline</v-icon
							>
							<span class="tw-font-medium tw-text-black">{{
								$t('branch.branchCode')
							}}</span>
						</div>
						<v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:rules="[rules.required($t('branch.branchCode'))]"
							v-model="branch.branchCode"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('branch.branchCodePlaceholder')"
							hide-details="auto"
							:error-messages="
								error.branchCode ? error.branchCode[0] : []
							"
						/>
					</div>
				</v-col>
				<v-col cols="12">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-file-document-outline</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('branch.generalDetails') }}
							</span>
						</div>
						<v-textarea
							outlined
							auto-grow
							rows="3"
							:rules="[rules.required($t('branch.generalDetails'))]"
							v-model="branch.details"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('branch.generalDetailsPlaceholder')"
							hide-details="auto"
							:error-messages="error.details ? error.details[0] : []"
						/>
					</div>
				</v-col>
			</v-row>

			<h3
				class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			>
				{{ $t('branch.nationalAddress') }}
			</h3>
			<v-row>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('signUp.buildingAddress') }}
							</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.buildingAddress'))]"
							v-model="branch.address.buildingNumber"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('signUp.buildingAddressPlaceholder')"
							hide-details="auto"
							:error-messages="
								error.buildingNumber ? error.buildingNumber[0] : []
							"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.streetName')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.streetName'))]"
							v-model="branch.address.streetName"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('signUp.streetNamePlaceholder')"
							hide-details="auto"
							:error-messages="
								error.streetName ? error.streetName[0] : []
							"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.neighbourhoodName')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.neighbourhoodName'))]"
							v-model="branch.address.neighborhoodName"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('signUp.neighbourhoodNamePlaceholder')"
							hide-details="auto"
							:error-messages="
								error.neighborhoodName ? error.neighborhoodName[0] : []
							"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.city')
							}}</span>
						</div>
						<v-text-field
							outlined
							v-model="branch.address.city"
							:rules="[rules.required($t('signUp.city'))]"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('signUp.cityPlaceholder')"
							hide-details="auto"
							:error-messages="error.city ? error.city[0] : []"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.postalCode')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.postalCode'))]"
							type="number"
							v-model="branch.address.postalCode"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('signUp.postalCodePlaceholder')"
							hide-details="auto"
							:error-messages="
								error.postalCode ? error.postalCode[0] : []
							"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5"
								>mdi-office-building-outline</v-icon
							>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.unitNumber')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.unitNumber'))]"
							type="number"
							v-model="branch.address.unitNumber"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('signUp.unitNumberPlaceholder')"
							hide-details="auto"
							:error-messages="
								error.unitNumber ? error.unitNumber[0] : []
							"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5"
								>mdi-office-building-outline</v-icon
							>
							<span class="tw-font-medium tw-text-black">{{
								$t('signUp.extraNumber')
							}}</span>
						</div>

						<v-text-field
							outlined
							:rules="[rules.required($t('signUp.extraNumber'))]"
							type="number"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('signUp.extraNumberPlaceholder')"
							v-model="branch.address.extraNumber"
							hide-details="auto"
							:error-messages="
								error.extraNumber ? error.extraNumber[0] : []
							"
						/>
					</div>
				</v-col>
			</v-row>
			<h3
				class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			>
				{{ $t('branch.branchRepresentative') }}
			</h3>
			<v-row>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-account-outline</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('branch.representativeName') }}
							</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('branch.representativeName'))]"
							v-model="branch.representativeName"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('branch.enterBranchRepresentative')"
							hide-details="auto"
							:error-messages="
								error.representativeName
									? error.representativeName[0]
									: []
							"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-account-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('branch.iqamaNumber')
							}}</span>
						</div>
						<v-text-field
							outlined
							:rules="[rules.required($t('branch.iqamaNumber'))]"
							v-model="branch.iqamaNumber"
							class="custom-outlined tw-rounded-md placeholder-padding"
							:placeholder="$t('branch.iqamaNumberPlaceholder')"
							hide-details="auto"
							:error-messages="
								error.iqamaNumber ? error.iqamaNumber[0] : []
							"
						/>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-phone</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('branch.phone')
							}}</span>
						</div>
						<v-text-field
							v-model="branch.representativePhone"
							:rules="[rules.required($t('branch.phone'))]"
							:placeholder="$t('branch.phonePlaceholder')"
							:error-messages="error.representativePhone"
							outlined
							type="tel"
							hide-details="auto"
							name="representativePhone"
							class="custom-outlined tw-rounded-md placeholder-padding"
						/>
					</div>
				</v-col>

				<v-col cols="12" md="6">
					<div>
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
							<span class="tw-font-medium tw-text-black">{{
								$t('branch.emailAddress')
							}}</span>
						</div>
						<v-text-field
							v-model="branch.representativeEmail"
							:rules="[rules.required($t('email')), rules.email]"
							:placeholder="$t('branch.emailAddressPlaceholder')"
							:error-messages="error.representativeEmail"
							outlined
							type="email"
							hide-details="auto"
							name="representativeEmail"
							class="custom-outlined tw-rounded-md placeholder-padding"
						/>
					</div>
				</v-col>
				<v-col cols="12">
					<div
						class="tw-border-dashed tw-border-2 tw-border-DF tw-rounded-lg tw-px-6 tw-py-4 tw-flex tw-items-center tw-justify-between tw-my-6"
					>
						<div class="tw-flex tw-flex-col tw-space-y-1.5">
							<span class="tw-font-medium">
								{{ $t('branch.letterOFAuth') }}
							</span>
							<span class="tw-font-medium tw-text-CA tw-text-xs">
								{{ $t('signUp.attachmentSizeHint') }}
							</span>
							<div v-if="copyOfCard.file && copyOfCard.src">
								<span class="tw-me-4">{{ $t('copyOfCard') }}</span>
								<a
									:href="
										copyOfCard.file &&
										copyOfCard.src &&
										copyOfCard.src
									"
									target="_blank"
								>
									<v-chip dark color="primary">
										<v-icon class="me-2">mdi-eye</v-icon>
										Copy Of Card
									</v-chip>
								</a>
							</div>
						</div>

						<v-btn
							@click="onRepresentativeAttachment"
							width="152"
							height="52"
							dark
							class="tw-rounded-md"
							depressed
							color="#F2CA51"
						>
							<icon-login class="tw-me-3 xl:tw-me-6" />
							<span
								class="xl:tw-text-lg tw-text-base tw-normal-case tw-font-normal"
							>
								{{ $t('actions.upload') }}
							</span>
						</v-btn>
					</div>
					<div
						class="tw-flex tw-justify-center tw-items-center tw-space-s-5 tw-py-6 tw-w-full"
					>
						<div class="tw-flex-1 tw-whitespace-nowrap">
							<span class="tw-text-black">{{
								$t('enable-branch')
							}}</span>
						</div>

						<v-switch
							v-model="branch.status"
							color="primary"
							class="tw-w-full"
						></v-switch>
					</div>
					<!-- <span
						v-if="showAddressAttachError"
						class="red--text tw-ml-2 tw-text-xs"
						>Proof of national address is required</span
					> -->
				</v-col>
			</v-row>
			<!-- <div class="tw-sticky tw-bottom-0 tw-z-50"> -->
			<div class="tw-flex tw-w-full tw-space-s-9 tw-bg-white">
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetUserForm"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}</span
					>
				</v-btn>
				<v-btn
					type="submit"
					height="58"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					depressed
					:loading="loading"
					color="primary"
					><span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>{{ $t('actions.submit') }}</span
					>
				</v-btn>
			</div>
			<!-- </div> -->
		</v-form>
	</div>
</template>

<script>
import { createFormMixin } from '@/mixins/form-mixin'
import UseFilePicker from '../../mixins/UseFilePicker'
import { mapActions, mapGetters } from 'vuex'
import { hasHistory, toFormData } from 'vuelpers'
import { MODELS } from '../../consts'

export default {
	name: 'CreateBranchForm',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	data() {
		return {
			branch: {
				name: '',
				branchCode: '',
				details: '',
				representativeName: '',
				representativeEmail: '',
				representativePhone: '',
				iqamaNumber: '',
				status: 1,
				address: {
					buildingNumber: '',
					streetName: '',
					neighborhoodName: '',
					city: '',
					postalCode: '',
					extraNumber: '',
					unitNumber: '',
				},
			},
			error: {},
			loading: false,
			copyOfCard: {
				file: null,
				attachedLink: null,
			},
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		formatDate() {
			console.log()
			return this.branch.iqamaCardExpDate
				? this.$m(this.branch.iqamaCardExpDate).format(
						this.$t('do-mmm-yyyy')
				  )
				: ''
		},
		branchable() {
			const branchableId =
				this.branch?.branchableId || this.$currentUser.isBuyer
					? this.$currentUser.buyer[0].id
					: this.$currentUser.supplier[0].id
			const branchableType =
				this.branch?.branchableType || this.$currentUser.isBuyer
					? MODELS.BUYER
					: MODELS.SUPPLIER
			return {
				branchableId,
				branchableType,
			}
		},
	},
	methods: {
		...mapActions('branch', ['createBranch']),
		async validateBranch() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			this.loading = true
			const [err] = await this.createBranch(
				toFormData(
					{
						...this.branch,
						...this.branchable,
						file: this.copyOfCard.file,
						status: +this.branch.status,
					},
					{},
					{ convertCase: 'snake_case' }
				)
			)
			this.loading = false

			if (err) {
				if (err.statusCode == 422) {
					this.error = err.errors
					this.$utils.scrollIntoError(this)
				}
				return this.$toast.error(
					this.$t('error-creating', [this.$t('branchText')])
				)
			}
			this.$toast.success(this.$t('success-create', [this.$t('branchText')]))
			this.$router.push(
				this.$currentUser.isBuyer ? '/branches' : '/supplier/branches'
			)
		},
		onRepresentativeAttachment() {
			this.openFilePicker({ multiple: false }, (image) => {
				console.log(image)
				this.copyOfCard = image
				this.showBuyerAttachmentError = false
			})
		},
		onResetUserForm() {
			hasHistory()
				? this.$router.go(-1)
				: this.$router.push(
						this.$currentUser.isBuyer ? '/branches' : '/supplier/branches'
				  )
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
